import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MessagesModel } from '../models/Messages.model';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem("token");
   httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json; charset=utf-8',
      // 'Authorization': "Bearer " + token,
       "Authorization": "Bearer " + this.token,
       "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }

  getAll(): Observable<MessagesModel[]> {
    debugger;
    return this.http
      .get<any>(`${this.baseUrl}/Messages/GetAll`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.toString()));
  }

  getById(id:number): Observable<MessagesModel> {
    return this.http
      .get<any>(`${this.baseUrl}/Messages/GetById/${id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.toString()));
  }
  createMessages(payload: MessagesModel): Observable<boolean> {
    return this.http
      .post<any>(`${this.baseUrl}/Messages/Insert`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.toString()));
  }

  put(payload: MessagesModel): Observable<boolean> {
    return this.http
      .post<any>(`${this.baseUrl}/Messages/Update`, payload,this.httpOptions)
      .pipe(catchError((error: any) => Observable.toString()));
  }

  delete(payload: MessagesModel): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Messages/Delete/${payload.Id}`,this.httpOptions)
      .pipe(catchError((error: any) => Observable.toString()));
  }
}
