import { MessagesService } from './../../services/message.service';
import { MessagesModel } from './../../models/messages.model';
import { SessionService } from 'src/app/pages/session/session.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

import { KatoraService } from '../../services/katora.service';
import { TicketModel } from 'src/app/models/ticket.model';
import { emailValidator } from 'src/app/core/utils/app-validators';

@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss']
})
export class CartDropdownComponent implements OnInit {
  public productAddedToCart;
  public productTotal;
  public cartCount;
  constructor(private messagesService:MessagesService, private katoraService : KatoraService,private fb:FormBuilder,private sessionService:SessionService) { 
  }
  form:FormGroup;
  message:MessagesModel={};
  ngOnInit() {
    debugger;
    this.message = new TicketModel();
    this.form = this.createForm();
  }
  openForm = false;
  createForm():FormGroup{
    this.openForm = true;
    return this.fb.group({
      Id:[this.message.Id],
      Name:[this.message.Name,Validators.required],
      Email:[this.message.Email,Validators.compose([Validators.required, emailValidator])],
      Message:[this.message.Message,Validators.required],
      Phone:[this.message.Phone,Validators.required],
      Subject:[this.message.Subject],
    })
  }
  ngAfterContentChecked(){
    this.productAddedToCart = this.katoraService.getProductFromCart();    
    this.productTotal =  this.katoraService.calculateTotal().toFixed(2);
    this.cartCount = this.katoraService.getCartCount();
   
  }
  removeProduct(product: any){
    this.katoraService.removeProductFromCart(product);
  }
  sendMessage(){
    this.message = this.form.getRawValue();
    this.messagesService.createMessages(this.message)
    .subscribe(data => {
      this.message = new MessagesModel();
      this.ngOnInit();
    })
  }
}
