import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-screen-banner',
  templateUrl: './full-screen-banner.component.html',
  styleUrls: ['./full-screen-banner.component.scss']
})
export class FullScreenBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
