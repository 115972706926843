import { Component, Input, OnInit } from '@angular/core';
import { GallaryModel } from './gallary-data.model';
import { GallaryService } from './gallary.service';
import { IAlbum, Lightbox } from 'ngx-lightbox';


@Component({
    // moduleId: module.id,
    selector: 'gallary-page',
    templateUrl: 'gallary-page.component.html',
    styleUrls: ['gallary-page.component.scss']
})
export class GallaryPageComponent implements OnInit {
    imageArr:GallaryModel[]=[];
    @Input() showCount =false;
    previewImage =false;
    showMask =false;
   currentLightboxImage:string =""; 
    currentIndex =0;
    controls =true;
    totalImageCount =0;
    ialboum:IAlbum[]=[];
    ial:IAlbum;
    constructor(
        public _lightbox: Lightbox,
      private  gallaryService:GallaryService,
    ){
          this.gallaryService.getAll().subscribe(
            data=>{ 
                debugger;
                this.imageArr=data;
                this.currentLightboxImage=this.imageArr[0].ImageUrl;
                this.totalImageCount=this.imageArr.length;
                this.imageArr.forEach(element => {
 
                    const album = {
                        src: element.ImageUrl,
                        caption: '',
                        thumb: element.ImageUrl
                    };
                    this.ialboum.push(album);
                });
            }
        )
    }

    ngOnInit(): void {
    
        
       
    }
    // imageArr :string[]= [
    //     'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
    //     'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //     'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
    //     'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //     'https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg',
    //     'https://hatrabbits.com/wp-content/uploads/2017/01/random.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //     'https://cdn.pixabay.com/photo/2022/08/11/14/28/woman-7379683_960_720.jpg',
    //   ];
    
   

    onPreviewImage(index:number):void{
        this.previewImage =true;
        this.showMask =true;
        this.currentIndex =index;
        this.currentLightboxImage= this.imageArr[index].ImageUrl
    }

    Close(){
        this.showMask =false;
    }
    next(){
        if(this.currentIndex <this.totalImageCount-1){
            this.currentLightboxImage= this.imageArr[this.currentIndex+1].ImageUrl
            this.currentIndex =this.currentIndex+1;

        }
    }
    previose(){
        if(this.currentIndex >0){
            this.currentLightboxImage= this.imageArr[this.currentIndex-1].ImageUrl
            this.currentIndex =this.currentIndex-1;

        }
    }
    open(index: number): void {
        // open lightbox
        this._lightbox.open(this.ialboum, index);
    }
    
    close(): void {
        // close lightbox programmatically
        this._lightbox.close();
    }
  
    }

