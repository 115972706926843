import { Component, OnInit } from '@angular/core';

import { NewsModel } from '../news-data.model';
import { NewsService } from '../product.service';

@Component({
    // moduleId: module.id,
    selector: 'news-page',
    templateUrl: 'news-page.component.html',
    styleUrls: ['news-page.component.scss']
})
export class NewsPageComponent implements OnInit {
    products:NewsModel[]=[];
    current_language = 'en';

    constructor(data:NewsService) {
      this.current_language = localStorage.getItem('lang')

      data.getAll().subscribe(
        data=>{
          this.products=data;
      console.warn(data)
        }
      )
     }
  
    ngOnInit() {
    }
  
  
  }
  