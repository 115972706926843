import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from '../product.service';
import { NewsModel } from '../news-data.model';

@Component({
    // moduleId: module.id,
    selector: 'news-details',
    templateUrl: 'news-details.component.html',
    styleUrls: ['news-details.component.scss']
})
export class NewsDetailsComponent {
    blog:NewsModel={};
    current_language = 'en';

    constructor(
       private activatedRoute: ActivatedRoute,data:NewsService) {
      this.current_language = localStorage.getItem('lang')

        const id = this.activatedRoute.snapshot.paramMap.get('id');
  
        data.getById(parseInt(id)).subscribe(
          data=>{
            this.blog=data;
        console.warn(data)
      // this.loadAlbum();
    }
    )
    // this.products= this.productHolder[id];
     console.warn(id)
  }
  slideProductConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "infinite": false,
    //"asNavFor": "slideProductNavConfig",
    "asNavFor": ".thumbs",
  };

  slideProductNavConfig = {
    "slidesToShow": 3,
    "slidesToScroll": 1,
    "asNavFor": ".feedback",
    //"asNavFor": "slideProductConfig",
    "centerMode": true,
    "centerPadding": '60px',
    "dots": true,
    "arrows": false,
    "focusOnSelect": true
  };

  slideRelatedProductConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": true,
    "infinite": false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  slickInit(e) {
  }
}
