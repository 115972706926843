import { Component, Input, OnInit } from '@angular/core';
import { NewsModel } from '../news-data.model';

@Component({
    // moduleId: module.id,
    selector: 'news-card',
    templateUrl: 'news-card.component.html',
    styleUrls: ['news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
    current_language = 'en';

    @Input() data: NewsModel;
  
    constructor() {
      this.current_language = localStorage.getItem('lang')

     }
  
    ngOnInit() {
    }
  
  }
  