import { ProjectModel } from './../../../models/project.model';
import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../../../services/project.service';
@Component({
    selector: 'projects',
    templateUrl: 'projects.component.html',
    styleUrls: ['projects.component.scss']
})
export class ProjectsComponent implements OnInit {

    constructor(private ProjectService:ProjectService) { }
  
    ngOnInit() {
        this.ProjectService.getAll()
        .subscribe(data => {
          this.products = data;
        })
    }
  
    products : ProjectModel[]=[]
  
  
  }
  