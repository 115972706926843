import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'our-customer-card',
    templateUrl: 'our-customer-card.component.html',
    styleUrls: ['our-customer-card.component.scss']
})
export class OurCustomerCardComponent implements OnInit {

    @Input() data: any;
    current_language = 'en';
  
  
    ngOnInit() {
      this.current_language = localStorage.getItem('lang')

    }
  
  
    constructor(){ }
  
 
  
  }
  