import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/components/serves-card/product.service';
import { ServesModel } from 'src/app/components/serves-card/serves-data.model';

@Component({
  selector: 'app-shop-one',
  templateUrl: './shop-one.component.html',
  styleUrls: ['./shop-one.component.scss']
})
export class ShopOneComponent implements OnInit {
  products:ServesModel[]=[];
  current_language = 'en';

  constructor(data:ServiceService) {
    this.current_language = localStorage.getItem('lang')
    data.getAll().subscribe(
      data=>{
        this.products=data;
    console.warn(data)
      }
    )
   }

  ngOnInit() {
  }


}
