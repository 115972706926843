import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MessagesModel } from 'src/app/models/messages.model';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { MessagesService } from 'src/app/services/message.service';

@Component({
  selector: 'app-contact-one',
  templateUrl: './contact-one.component.html',
  styleUrls: ['./contact-one.component.scss']
})
export class ContactOneComponent implements AfterViewInit,OnInit {

  
  
  constructor(private fb:FormBuilder,private messagesService:MessagesService) { }
  ngOnInit(): void {
    this.form = this.createForm();
  }


  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  map: google.maps.Map;
  lat = 40.73061;
  lng = -73.935242;
  message : MessagesModel = {};
  form : FormGroup ;
  createForm():FormGroup{
    return this.fb.group({
      Id:[this.message.Id],
      Name:[this.message.Name,Validators.required],
      Email:[this.message.Email,Validators.compose([Validators.required, emailValidator])],
      Message:[this.message.Message,Validators.required],
      Phone:[this.message.Phone,Validators.required],
      Subject:[this.message.Subject,Validators.required],
    })
  }
  coordinates = new google.maps.LatLng(this.lat, this.lng);

  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8
  };

  marker = new google.maps.Marker({
    position: this.coordinates,
    // map: this.map,
    title: 'Hello World!'
  });

  ngAfterViewInit() {
    this.mapInitializer();
  }

  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker.setMap(this.map);
  }
  sendMessage(){
    this.message = this.form.getRawValue();
    this.messagesService.createMessages(this.message)
    .subscribe(data => {
      this.message = new MessagesModel();
      this.ngOnInit();
    })
  }
}
