import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'project-card',
    templateUrl: 'project-card.component.html',
    styleUrls: ['project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
    @Input() data: any;
  current_language = 'en';

    ngOnInit() {
    
    }
    constructor(){ 
      this.current_language = localStorage.getItem('lang')
      
    }
  }
  