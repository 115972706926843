import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { emailValidator, matchingPasswords } from 'src/app/core/utils/app-validators';
import { RegistrationModel } from '../../session/models/registration.model';
import { SessionService } from '../../session/session.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  type = 1;
  user: RegistrationModel = {};
  password: string;
  form:FormGroup;
  openForm=false;
  constructor(private userService: SessionService , public toaster:ToastrService,private fb:FormBuilder) { }
  createForm():FormGroup{
    this.openForm = true;
    return this.fb.group({
      FullName:[this.user.FullName,Validators.compose([Validators.required, Validators.minLength(3)])],
      Email:[this.user.Email,Validators.compose([Validators.required, emailValidator])],
      Password:[this.user.Password, Validators.compose([Validators.required,  Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
      ConfirmPassword: ['' , Validators.required],
      Phone:[this.user.Phone]
    }, {validator: [matchingPasswords('Password', 'ConfirmPassword')]});
  }
  ngOnInit() {
    this.userService.getCurrentUser()
    .subscribe(data => {
      this.user = data;
      if ( data.Id == 0 ) {
        this.type = 1;
        this.user = new RegistrationModel();
        this.form = this.createForm();
      }
      else {
        this.type = 2;
        this.user = data;
        this.form = this.createForm();
      }
    });
  }

}
