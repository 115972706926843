import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.scss']
})
export class HeaderTwoComponent implements OnInit {
  token:string='';

  constructor() { 
    this.token = localStorage.getItem('token');


  }

  ngOnInit() {
  }

}
