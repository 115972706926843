import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss'],
  providers: [DatePipe]
})
export class FooterOneComponent implements OnInit {

   myDate = '';
  constructor(private datePipe: DatePipe){
      this.myDate = this.datePipe.transform(new Date(), 'y');
  }
  ngOnInit() {
    
  }

}
