// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ComponentsModule } from 'src/app/components/components.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';

// This Module's Components
import { OurCustomerComponent } from './our-customer.component';

@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        TranslateModule,
        
        ComponentsModule,
        
        HeaderModule,
        FooterModule,
        SidebarModule
    ],
    declarations: [
        OurCustomerComponent,
    ],
    exports: [
        OurCustomerComponent,
    ]
})
export class OurCustomerModule {

}
