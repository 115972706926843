export class RegistrationModel{
  Id?: number;
  UserId               ?: string;
  FullName             ?: string;
  UserName             ?: string;
  Email                ?: string;
  Phone                ?: string;
  Password             ?: string;
  constructor(item ?: RegistrationModel){
    item = item ? item : {};
    this.Id = item.Id ? item.Id : 0;
    this.UserId                = item.UserId                ? item.UserId                : '';
    this.FullName              = item.FullName              ? item.FullName              : '';
    this.UserName              = item.UserName              ? item.UserName              : '';
    this.Email                 = item.Email                 ? item.Email                 : '';
    this.Phone                 = item.Phone                 ? item.Phone                 : '';
    this.Password              = item.Password              ? item.Password              : '';
  }
}