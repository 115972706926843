import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: 'root'
})
export class SessionService  {
  baseUrl = environment.DevApiUrl;
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();
  public loggedIn = false;
  constructor(private http: HttpClient) {
    this.loggedIn = !!localStorage.getItem('token');
    this._authNavStatusSource.next(this.loggedIn);
  }
  register(user: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/register`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  registerAndSendPassword(user: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/registerAndSendPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getCurrentUser(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .get(`${this.baseUrl}/Account/GetCurrentUserData`, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  ConfirmRegister(user: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ConfirmRegister`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  changePassword(user: any): Observable<any> {
  const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + token,
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ChangePassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  ForgotPassword(user: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ForgotPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  resetPassword (user: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ResetPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
 
  login(user: any) {
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
    return this.http.post<any>(`${this.baseUrl}/account/token`, user,httpOptions)
        .pipe(map( user => {
            if (user) {
              localStorage.setItem('token', user.token);
              this.loggedIn = true;
              localStorage.setItem('loggedIn', 'true');
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              return true;
            }
            else{
              return false;
            }
        })
        );
}
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserSettings');
    this.loggedIn = false;
    localStorage.setItem('loggedIn', 'false');
    this._authNavStatusSource.next(false);
  }
  isLoggedIn() {
    if(localStorage.getItem('loggedIn') === 'true'){
        return true;
    }
    else{
        return false;
    }
  }
  facebookLogin(accessToken:string) {
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
        let body = JSON.stringify({ accessToken });  
    return this.http.post<any>(`${this.baseUrl}/externalauth/facebook`, body,httpOptions)
        .pipe(map(user => {
            if (user) {
              var myUser = JSON.parse(user);
              localStorage.setItem('auth_token', myUser.auth_token);
              this.loggedIn = true;
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              return true;
            }
            return true;
        })
        );
}
  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  CheckroleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if(userRole==allowedRoles){
      return true;
    }
    else
    return false
  }

  GetUserFullNameName(): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    return payLoad.Email;
  
  }
}

