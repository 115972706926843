import { TicketService } from './../../services/ticket.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TicketModel } from 'src/app/models/ticket.model';

@Component({
    selector: 'add-ticket',
    templateUrl: 'add-ticket.component.html',
    styleUrls: ['add-ticket.component.scss']
})
export class AddTicketComponent implements OnInit {
    form : FormGroup;
    ticket:TicketModel = {};
    openForm = false;
    constructor(
      private fb: FormBuilder,
      public toaster:ToastrService,
      private router: Router,
      private ticketService:TicketService
    ) { }
   createForm():FormGroup{
    this.openForm = true;
    return this.fb.group({
        Id          :[this.ticket.Id          ],
        Titel       :[this.ticket.Titel,Validators.required       ],
        ProjectName :[this.ticket.ProjectName,Validators.required ],
        Description :[this.ticket.Description,Validators.required ],
        Stutus      :[this.ticket.Stutus      ],
        SolveComment:[this.ticket.SolveComment],
        createDate  :[this.ticket.createDate  ],
        SolveDate   :[this.ticket.SolveDate   ],
        UserId      :[this.ticket.UserId      ],
        Phone       :[this.ticket.Phone       ],
        Email       :[this.ticket.Email       ],
    })
   }
    ngOnInit() {
        this.ticket = new TicketModel();
      this.form = this.createForm();
    }
    submit(){
        debugger;
      this.ticket = this.form.getRawValue();
        this.ticketService.createTicket(this.ticket)
        .subscribe(result => {
            if(result == true){
                this.toaster.success('Ticket Send Success');
                this.router.navigate(['/']);
            }
        })
    }
  
  }
  