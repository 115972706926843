import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {
  current_language = 'en';
  token:string='';

  constructor() {
    this.current_language = localStorage.getItem('lang')
    this.token = localStorage.getItem('token');

   }

  ngOnInit() {
  }

}
