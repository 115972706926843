// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { LightboxModule } from 'ngx-lightbox';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BackToTopComponent } from 'src/app/components/back-to-top/back-to-top.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NewsletterModalComponent } from 'src/app/components/newsletter-modal/newsletter-modal.component';
import { PageLoaderComponent } from 'src/app/components/page-loader/page-loader.component';
import { SwitcherComponent } from 'src/app/components/switcher/switcher.component';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';

// This Module's Components
import { NewsDetailsComponent } from './news-details.component';

@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        TranslateModule,
        NgxImageZoomModule.forRoot(),
        LightboxModule,
        SlickCarouselModule,
        ComponentsModule,
        
        HeaderModule,
        FooterModule,
        SidebarModule
    ],
    declarations: [
        NewsDetailsComponent,
    ],
    exports: [
        NewsDetailsComponent,
    ]
})
export class NewsDetailsModule {

}
