import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accept-cookie',
  templateUrl: './accept-cookie.component.html',
  styleUrls: ['./accept-cookie.component.scss']
})
export class AcceptCookieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
