export class MessagesModel{
    Id?: number;
    Message?: string;
    Name?: string;
    Email?: string;
    Phone?: string;
    Subject?: string;
    constructor(item ?: MessagesModel){
        item = item ? item :{};
        this.Id = item.Id ? item.Id : 0;
        this.Message = item.Message ? item.Message : '';
        this.Name = item.Name ? item.Name : '' ;
        this.Email = item.Email ? item.Email : '';
        this.Phone = item.Phone ? item.Phone : '';
        this.Subject = item.Subject ? item.Subject : '';
    }
}