import { AboutModel } from './../../../models/about.model';
import { AboutService } from './../../../services/about.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-one',
  templateUrl: './about-one.component.html',
  styleUrls: ['./about-one.component.scss']
})
export class AboutOneComponent implements OnInit {

  constructor(private aboutService:AboutService) { 
    this.current_language = localStorage.getItem('lang')

  }
  aboutUs:AboutModel[]=[];
  current_language = 'en';

  ngOnInit() {
    this.aboutService.getAll()
    .subscribe(data => {
      this.aboutUs = data;
    })
  }

  slideTeamConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 4,
    "dots": true,
    "infinite": false,

    responsive: [
      {
      breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
      breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  slickInit(e) {
  }
}
