import { FormGroup, FormControl } from '@angular/forms';

export function emailValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;    
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}
export function phoneValidator(control: FormControl): {[key: string]: any} {
    var emailRegexp = /9665[0-9]{7}$/;    
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidPhone: true};
    }
}

export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        let password= group.controls[passwordKey];
        let passwordConfirmation= group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true})
        }
    }
}
export function checkSoudi(isSoudiKey: string, phoneKey: string) {
    debugger;
    return (group: FormGroup) => {
        let isSoudi= group.controls[isSoudiKey];
        let phone= group.controls[phoneKey];
        let checkEmpty = true;
        if (isSoudi.value != true && (phone.hasError('required') == true || phone.hasError('invalidPhone') == true)) {
            phone.setErrors([])
            checkEmpty = false;
            phone.setValue('');
            group.controls[phoneKey].clearValidators();
            group.controls[phoneKey].setValidators([]);
            phone.updateValueAndValidity();
       
            return ;
        }
        else if(checkEmpty){
            if((!phone.value || phone.value == "") && isSoudi.value == true){
                return phone.setErrors({required:true})
            }
            else if(isSoudi.value == true){
                var emailRegexp = /9665[0-9]{8}$/;    
                if (phone.value && !emailRegexp.test(phone.value)) {
                    return phone.setErrors({invalidPhone: true});
                }
            }

        }
    }
}