import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form : FormGroup;
  ForgotPasswordModel = {};
  openForm = false;
  constructor(
    private fb: FormBuilder,
    private sessionService:SessionService,
    public toaster:ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
 
    this.form = this.fb.group({
      email: ['', Validators.compose([Validators.required, emailValidator])],
    })
    this.openForm = true;
  }
  submit(){
    this.ForgotPasswordModel = this.form.getRawValue();
    this.sessionService.ForgotPassword(this.ForgotPasswordModel)
    .subscribe(result => {
      if(result === 'Password incorrect'){
        this.toaster.error('Password incorrect');
      }
      else{
        this.toaster.success('Check your email for instructions to Reset Password');
        this.router.navigate(['login']);
      }
    });
  }

}
