export class TicketModel{
    Id          ?: number;
    Titel       ?: string;
    ProjectName ?: string;
    Description ?: string;
    Stutus      ?: string;
    SolveComment?: string;
    createDate  ?: Date;
    SolveDate   ?: Date;
    UserId      ?: string;
    Phone       ?: string;
    Email       ?: string;
    constructor(item?: TicketModel){
        item = item ? item : {};
        this.Id = item.Id ? item.Id : 0;
        this.Titel = item.Titel ? item.Titel : '';
        this.ProjectName  = item.ProjectName  ? item.ProjectName  : '';
        this.Description  = item.Description  ? item.Description  : '';
        this.Stutus       = item.Stutus       ? item.Stutus       : '';
        this.SolveComment = item.SolveComment ? item.SolveComment : '';
        this.createDate   = item.createDate   ? item.createDate   : null;
        this.SolveDate    = item.SolveDate    ? item.SolveDate    : null;
        this.UserId       = item.UserId       ? item.UserId       : '';
        this.Phone        = item.Phone        ? item.Phone        : '';
        this.Email        = item.Email        ? item.Email        : '';
    }
}