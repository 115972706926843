import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsletter-modal',
  templateUrl: './newsletter-modal.component.html',
  styleUrls: ['./newsletter-modal.component.scss']
})
export class NewsletterModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
