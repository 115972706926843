import { Component, Input, OnInit } from '@angular/core';
import { KatoraService } from 'src/app/services/katora.service';
import { ServesModel } from './serves-data.model';

@Component({
    // moduleId: module.id,
    selector: 'serves-card',
    templateUrl: 'serves-card.component.html',
    styleUrls: ['serves-card.component.scss']
})
export class ServesCardComponent implements OnInit {
  current_language = 'en';

    @Input() data: ServesModel;
  
  
    ngOnInit() {
      console.log("service",this.data)
    }
  
  
    constructor(private katoraService : KatoraService){ 
      this.current_language = localStorage.getItem('lang')

    }
  
    onAddCart(product:ServesModel ){
  
      this.katoraService.addProductToCart(product);  
      
    }
  
    onAddWishlist(product: ServesModel){
      
      this.katoraService.addProductToWishlist(product);
  
    }
  
  }
  