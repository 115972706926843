import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { MessagesModel } from 'src/app/models/messages.model';
import { SessionService } from 'src/app/pages/session/session.service';
import { MessagesService } from 'src/app/services/message.service';

@Component({
    selector: 'message-modal',
    templateUrl: 'message-modal.component.html',
    styleUrls: ['message-modal.component.scss']
})
export class MessageModalComponent implements OnInit {
    public productAddedToCart;
    public productTotal;
    public cartCount;
  current_language = 'en';

    constructor(private messagesService:MessagesService,private fb:FormBuilder,private sessionService:SessionService) { 
      this.current_language = localStorage.getItem('lang')
   
    }
    form:FormGroup;
    message:MessagesModel={};
    ngOnInit() {
      debugger;
      this.message = new MessagesModel();
      this.form = this.createForm();
    }
    openForm = false;
    createForm():FormGroup{
      this.openForm = true;
      return this.fb.group({
        Id:[this.message.Id],
        Name:[this.message.Name,Validators.required],
        Email:[this.message.Email,Validators.compose([Validators.required, emailValidator])],
        Message:[this.message.Message,Validators.required],
        Phone:[this.message.Phone,Validators.required],
        Subject:[this.message.Subject],
      })
    }
 
    sendMessage(){
      this.message = this.form.getRawValue();
      this.messagesService.createMessages(this.message)
      .subscribe(data => {
        this.message = new MessagesModel();
        this.ngOnInit();
      })
    }
  }
  