import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { emailValidator } from 'src/app/core/utils/app-validators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-log-in-one',
  templateUrl: './log-in-one.component.html',
  styleUrls: ['./log-in-one.component.scss']
})
export class LogInOneComponent implements OnInit {
  form:FormGroup;
  current_language='en';
  constructor(private sessionService:SessionService,private fb:FormBuilder,private router:Router, public toaster: ToastrService) { 
    this.current_language = localStorage.getItem('lang');
  }
  createForm():FormGroup{
    return this.fb.group({
      Email:['',Validators.compose([Validators.required, emailValidator])],
      Password:['', Validators.compose([Validators.required,  Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])]
    })
  }
  ngOnInit() {
    this.form = this.createForm();
  }
  login(value) {
    debugger;
    this.sessionService.login(value)
    .subscribe(data => {
       if (data === true) {
          this.router.navigate(['']);
          if(this.current_language != 'ar'){
            this.toaster.success('Welcome');
          }
          else{
            this.toaster.success('مرحبا بك');
          }
       }},
       error => {
         if(this.current_language != 'ar'){
           this.toaster.error('This is not good!', 'Email or Password is not true');
         }
         else{
          this.toaster.error('الرجاء المحاولة مرة اخري', 'البريد الالكتروني او كلمة المرور غير صحيحية');
         }
        });
 }

}
