import { SessionService } from './../session.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { checkSoudi, emailValidator, matchingPasswords, phoneValidator } from 'src/app/core/utils/app-validators';
import { RegistrationModel } from '../models/registration.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  form:FormGroup;
  openForm=false;
  user:RegistrationModel={};
  constructor(private sessionService:SessionService,private fb:FormBuilder,public router:Router) { }
  createForm():FormGroup{
    return this.fb.group({
      FullName:[this.user.FullName,Validators.compose([Validators.required, Validators.minLength(3)])],
      Email:[this.user.Email,Validators.compose([Validators.required, emailValidator])],
      Password:[this.user.Password, Validators.compose([Validators.required,  Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
      ConfirmPassword: ['' , Validators.required],
      Phone:[this.user.Phone]
    }, {validator: [matchingPasswords('Password', 'ConfirmPassword')]});
  }
  ngOnInit() {
    this.form = this.createForm();
    this.openForm = true;

  }
  register(value){
    debugger;
    value.userName = value.email;
    this.user = value;
    this.sessionService.register(value)
    .subscribe(data => {
      localStorage.setItem('token', data.token);
      this.sessionService.loggedIn = true;
      localStorage.setItem('loggedIn', 'true');
      localStorage.setItem('currentUser', JSON.stringify(data));
      this.router.navigate([''])
      })
      }
}
