// Angular Imports
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { FooterModule } from 'src/app/layouts/footer/footer.module';
import { HeaderModule } from 'src/app/layouts/header/header.module';
import { SidebarModule } from 'src/app/layouts/sidebar/sidebar.module';
import { ComponentsModule } from '../components.module';

// This Module's Components
import { GallaryPageComponent } from './gallary-page.component';
import { GallaryService } from './gallary.service';

@NgModule({
    imports: [

          // modules (angular)
          CommonModule,
          FormsModule,
          ReactiveFormsModule,
          CommonModule,
          NgxSpinnerModule,
          BrowserAnimationsModule,
          AppRoutingModule,
          TranslateModule,
          ComponentsModule,
          
          HeaderModule,
          FooterModule,
          SidebarModule
          // modules (third-party)
          
    ],
    declarations: [
        GallaryPageComponent,
    ],
    exports: [
        GallaryPageComponent,
    ]
})
export class GallaryPageModule {

}
